.button {
    display: flex;
    flex-direction:row;
    margin-bottom: 10px;
    justify-content: center;
     width: 100%;
    height: 100%;
}

.button11{ 
    flex: 40%;
    width: 100%;
    height: 100%;
} 

.button12 { 
    flex: 40%;  
    width: 100%;
    height: 100%;
}
.button1{ 
    flex: 20%;
    width: 100%;
    height: 100%;
}
.button2{ 
    flex: 20%;
    width: 100%;
    height: 100%;
}

.buttondomain{ 
    background-color: #316685;
    color:white;
    border-radius: 10px;
    margin: 10px 0px;
    margin-left: 20px;
    cursor: pointer;
    font-size:10px;
    width: 100px;
}
