.indic-table th ,.indic-table td { 
    border :1px solid #258BAF; 
    text-align: center;
    padding: 1px 5px;
    font-size:13px ;
    color: black;
}
.indic-table th { 
    border :1px solid #258BAF; 
    text-align: center;
    padding: 1px 5px;
    font-size:15px ;
    color:#258BAF;
}

 
.indic-table { 
    border-collapse: collapse;
 
}
thead {
  
    position: sticky;
    top: 0;
    z-index: 1;
  }
  th{
    background-color: white;
  }
.myStyle{
    border: none;
    color: #258BAF;
    outline: none;
    font-weight:bold;
    font-size: 2.1vh;
  }
  select option {
    font-size: 12px;
    height: "12px";
    width:"12px"; /* Adjust the font size as needed */
  }
.navigation_indicateur{
  background-color: #258BAF;
  margin-left: 20px;
  width: 16vh;
  height: 7vh;
  margin: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  color: white;
  font-size: 15px;
  cursor: pointer; 
}
.sous{
  background-image:linear-gradient(rgba(32, 128, 1, 0.7), rgba(32, 128, 1, 0.7)),url('../components/images/sous-domaines.webp');
  display: flex;
  justify-content: center; 
  align-items: center; 
  width: 100%; 
  height: 20vh; 
  margin-bottom: 2.5rem; 
  border: 0.2rem solid #208001; 
  padding: 0.5rem;
}
.title1{
  font-weight: bold; 
  background-color: none; 
  color: #fff; 
  font-size: 3rem;
}
.card11{
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0px;
  top: 35%;
  gap: 0.2rem;
}
.btnIcon11{
  width: 8rem;
}
.carouselicon11{
  height: 3.125rem;
  width: 3.125rem;
  align-self: center;
}
.titreES11{
  font-size: 1.4rem;
}
.fordiv_contain{
  color: white;
  font-size: 2rem;
  font-weight: bold;
}
.fordiv{
  display: flex;
  justify-content: center;
  background-color: #208001;
  margin-bottom: 1rem;
  padding: 0.2rem;
  border-radius: 0.5rem;
  width: 80%;
}
.stl3{
  width: 100%;
  height: 60vh;
}
.stl2{
  display: flex;
  justify-content: center;
  /* padding-bottom: 1.875rem; */
  width: 80%;
  border: 1px solid #208001;
  margin-bottom: 5rem;
}

@media screen and (min-width: 960px) and (max-width: 1450px){
  .sous{
    background-image:linear-gradient(rgba(32, 128, 1, 0.7), rgba(32, 128, 1, 0.7)),url('../components/images/sous-domaines.webp');
    display: flex;
    justify-content: center; 
    align-items: center; 
    width: 100%; 
    height: 15vh; 
    margin-bottom: 1rem; 
    border: 0.2rem solid #208001; 
    padding: 0.5rem;
  }
  .title1{
    font-weight: bold; 
    background-color: none; 
    color: #fff; 
    font-size: 1.8rem;
  }
  .btnIcon11{
    width: 4.5rem;
  }
  .carouselicon11{
    height: 1.75rem;
    width: 1.75rem;
    align-self: center;
  }
  .titreES11{
    font-size: 0.7rem;
  }
  .fordiv_contain{
    color: white;
    font-size: 1.2rem;
    font-weight: bold;
  }
  .fordiv{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #208001;
    margin-bottom: 1rem;
    padding: 0.rem;
    border-radius: 0.2rem;
    width: 80%;
  }
  .stl3{
    width: 100%;
    height: 60vh;
  }
}


@media (max-width: 960px) { 
    
    td { 
        font-size:15px ;
    }
    .indic-table th ,.indic-table td { 
        border :1px solid #258BAF; 
        text-align: center;
        padding: 1px 10px;
        font-size:10px ;
        color: black;
    }
    .indic-table th { 
        border :1px solid #258BAF; 
        text-align: center;
        padding: 1px 10px;
        font-size:10px ;
        color:#258BAF;
    }
    .sous{
      background-image:linear-gradient(rgba(32, 128, 1, 0.7), rgba(32, 128, 1, 0.7)),url('../components/images/sous-domaines.webp');
      display: flex;
      justify-content: center; 
      align-items: center; 
      width: 100%; 
      height: 10vh; 
      margin-bottom: 1rem; 
      border: 0.2rem solid #208001; 
      padding: 0.5rem;
    }
    .title1{
      font-weight: bold; 
      background-color: none; 
      color: #fff; 
      font-size: 1.2rem;
    }
    .card11{
      display: flex;
      flex-direction: column;
      position: fixed;
      left: 0%;
      top: 60%;
      gap: 0.2rem;
    }
  .carouselicon11{ 
      height: 0.9rem;
      width: 0.9rem;
      align-self: center;
  }
  .btnIcon11{
      width: 3.5rem;
      height: 7vh;
  }
  .titreES11{
      font-size: 0.5rem;
  } 
  .fordiv_contain{
    color: white;
    font-size: 1rem;
    font-weight: bold;
  }
  .fordiv{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #208001;
    margin-bottom: 1rem;
    padding: 0.rem;
    border-radius: 0.2rem;
    width: 80%;
  }
  .stl2{
    display: flex;
    justify-content: center;
    /* padding-bottom: 1.875rem; */
    width: 80%;
    border: 1px solid #208001;
    margin-bottom: 2rem;
  }

}