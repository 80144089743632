.monstyle11{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.monstyle22{
    display: flex; 
    flex-direction: row;
    justify-content: center; 
    align-items: center; 
    width: 100%;
    gap: 10rem;
}
.monstyle33{
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    width: 100%;
    gap: 1rem;
}
.monstyle331{
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    width: 100%;
    gap: 1rem;
}
.monstyle44{
    border-radius: 5%; 
    height: 25vh; 
    width: 10vw;
}
.monstyle55{
    font-size: 1.2rem;
}

@media screen and (min-width: 960px) and (max-width: 1450px){
    .monstyle55{
        font-size: 0.8rem;
    }
}

@media (max-width: 960px){
    .monstyle22{
        display: block; 
        justify-content: center; 
        align-items: center; 
        width: 100%;
        gap: 10rem;
    }
    .monstyle33{
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        width: 100%;
        gap: 2rem;
    }
    .monstyle331{
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        width: 100%;
        gap: 2rem;
        margin-bottom: 1rem;
    }
    .monstyle44{
        border-radius: 5%; 
        height: 15vh; 
        width: 2vw;
    }
    .monstyle55{
        font-size: 0.8rem;
    }
}